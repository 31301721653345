import { map, take, debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { fromEvent, merge, Observable, BehaviorSubject } from 'rxjs';
import { ElementRef, Injectable } from '@angular/core';
import { orderBy } from 'lodash';
import { HttpService } from '../http.service';
import { APIService } from '../../app.constants';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/table';
import { Group } from '../../models/group';

@Injectable()
export class GroupService {
  private baseUrl = APIService.Groups;
  private _groups: BehaviorSubject<Group[]>;
  public readonly groups: Observable<Group[]>;
  private dataStore: {
    groups: Group[]
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { groups: [] };
    this._groups = <BehaviorSubject<Group[]>>new BehaviorSubject([]);
    this.groups = this._groups.asObservable();
  }

  get data(): Group[] { return this._groups.value; }

  get(id: string) {
    return this.groups.pipe(map(agencies => agencies.find(item => item.id.toString() === id.toString())), take(1));
  }

  loadAll(agencyId: string, type?: string): any {
    const params: any = { agency_id: agencyId };

    if (type) {
      params.type = type;
    }

    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.groups = response['data'];
      this._groups.next(Object.assign({}, this.dataStore).groups);
    }), take(1)).subscribe();
  }

  load(id: string): any {
    return this.httpService.get(`${this.baseUrl}/${id}`).pipe(map(response => {
      let notFound = true;

      this.dataStore.groups.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.groups[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.groups.push(response['data']);
      }

      this._groups.next(Object.assign({}, this.dataStore).groups);
    }), take(1)).subscribe();
  }

  create(group: Group) {
    return this.httpService.post(this.baseUrl, group).pipe(map(response => {
      group.id = response['data'].id;
      this.dataStore.groups.push(group);
      this._groups.next(Object.assign({}, this.dataStore).groups);
      return response;
    }), take(1));
  }

  update(group: Group, agencyId) {
    return this.httpService.post(`${this.baseUrl}/${group.id}?agency_id=${agencyId}`, group).pipe(map(response => {
      this.dataStore.groups.forEach((c, i) => {
        if (c.id === group.id) { this.dataStore.groups[i] = group; }
      });

      this._groups.next(Object.assign({}, this.dataStore).groups);
      return response;
    }), take(1));
  }

  archive(id: string, agencyId: string) {
    return this.httpService.post(`${this.baseUrl}/${id}/archive?agency_id=${agencyId}`).pipe(map(() => {
      this.dataStore.groups.forEach((c, i) => {
        if (c.id === id) { this.dataStore.groups.splice(i, 1); }
      });

      this._groups.next(Object.assign({}, this.dataStore).groups);
    }), take(1));
  }

  unarchive(id: string, agencyId: string) {
    return this.httpService.post(`${this.baseUrl}/${id}/unarchive?agency_id=${agencyId}`).pipe(map(() => {
      this.dataStore.groups.forEach((c, i) => {
        if (c.id === id) { this.dataStore.groups.splice(i, 1); }
      });

      this._groups.next(Object.assign({}, this.dataStore).groups);
    }), take(1));
  }

  remove(id: string, agencyId: string) {
    return this.httpService.delete(`${this.baseUrl}/${id}?agency_id=${agencyId}`).pipe(map(() => {
      this.dataStore.groups.forEach((c, i) => {
        if (c.id === id) { this.dataStore.groups.splice(i, 1); }
      });

      this._groups.next(Object.assign({}, this.dataStore).groups);
    }), take(1));
  }

  updateUsers(groupId: string, userIds, agencyId?) {
    return this.httpService.post(`${this.baseUrl}/${groupId}/users?agency_id=${agencyId}`, { user_ids: userIds });
  }

  toDataSource(paginator: MatPaginator, sort: MatSort, filter: ElementRef): GroupDataSource {
    return new GroupDataSource(this, paginator, sort, filter);
  }

}

export class GroupDataSource extends DataSource<any> {
  constructor(private _service: GroupService,
    private _paginator: MatPaginator,
    public _sort: MatSort,
    public _filter: ElementRef) {
    super();
  }

  connect(): Observable<Group[]> {
    const displayDataChanges = [
      this._service.groups,
      this._paginator.page,
      this._sort.sortChange,
      fromEvent(this._filter.nativeElement, 'keyup').pipe(debounceTime(150), distinctUntilChanged())
    ];

    // If the user changes the sort order, reset back to the first page.
    this._sort.sortChange.subscribe(() => {
      this._paginator.pageIndex = 0;
    });

    return merge(...displayDataChanges).pipe(startWith(null), map(() => {
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return this.getSortedData().splice(startIndex, this._paginator.pageSize).filter((item: Group) => {
        const searchStr = (item.name + item.desc + item.users).toLowerCase();
        return searchStr.indexOf(this._filter.nativeElement.value.toLowerCase()) !== -1;
      });
    }));
  }
  disconnect() { }

  private getSortedData(): Group[] {
    const data = this._service.data.slice();
    if (!this._sort.active || this._sort.direction === '') { return data; }

    switch (this._sort.active) {
      case 'name': return orderBy(data, ['name'], this._sort.direction);
      case 'desc': return orderBy(data, ['desc'], this._sort.direction);
      case 'users': return orderBy(data, ['users.length'], this._sort.direction);
    }
  }
}
