import { environment } from './../../environments/environment.prd';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { HttpService } from './http.service';
import { AgencyService } from './api.services/agency.service';
import { UserService } from './api.services/user.service';
import { GroupService } from './api.services/group.service';
import { LicenseService } from './api.services/license.service';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySpinner as MatSpinner, MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomHttpInterceptor } from './http.interceptor';
import { ActivityService } from './api.services/activity.service';
import { ActivityNamePipe } from './pipes/activity-name.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { ActivityDetailsPipe } from './pipes/activity-details.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgencyCreateComponent } from 'app/agencies/create/agency-create.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgmCoreModule } from '@agm/core';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UserCreateComponent } from 'app/users/create/user-create.component';
import { LicenseCreateComponent } from 'app/licenses/create/license-create.component';
import { SettingsDialogComponent } from './components/settings-dialog/settings-dialog.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PartnerCollaborationComponent } from './components/partner-collaboration/partner-collaboration.component';
import { LicenseProductNamePipe } from './pipes/license-product-name.pipe';

@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: environment.google_api_key,
            libraries: ['places']
        }),
        ScrollingModule,
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        HttpClientModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatPaginatorModule,
        MatTableModule,
        MatTabsModule,
        MatListModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        FlexLayoutModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
    declarations: [
        ConfirmDialogComponent,
        CapitalizePipe,
        ActivityNamePipe,
        KeysPipe,
        ActivityDetailsPipe,
        AgencyCreateComponent,
        UserCreateComponent,
        LicenseCreateComponent,
        SettingsDialogComponent,
        CountdownTimerComponent,
        PartnerCollaborationComponent,
        LicenseProductNamePipe,
    ],
    providers: [
        HttpService,
        AgencyService,
        UserService,
        GroupService,
        ActivityService,
        LicenseService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        }
    ],
    exports: [
        CapitalizePipe,
        ActivityNamePipe,
        KeysPipe,
        ActivityDetailsPipe,
        AgencyCreateComponent,
        PartnerCollaborationComponent,
        LicenseProductNamePipe
    ]
})
export class SharedModule { }
